import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ThankYou = ({ data }) => (
  <>
    <BackgroundImage
      Tag="main"
      className="min-h-screen px-4 mx-auto text-center bg-top bg-cover sm:bg-top py-36 sm:px-6 lg:px-8 lg:py-48"
      fluid={data.image.fluid}
      backgroundColor="#072837"
    >
      <h1 className="mt-4 text-4xl font-bold text-white font-headline sm:text-5xl">
        {data.heading}
      </h1>
      <p className="mt-3 text-lg text-black text-opacity-50">
        {data.subtitle}
      </p>
      <Link
        to={`/${data.locale}`}
        className="inline-flex items-center px-4 py-2 mt-6 text-sm font-medium text-black text-opacity-75 bg-white bg-opacity-75 border border-transparent rounded-md sm:bg-opacity-25 sm:hover:bg-opacity-50 sm:mt-8"
      >
        {data.ctaTitle}
      </Link>
    </BackgroundImage>
  </>
)

export default ThankYou
